import { Auth } from "@aws-amplify/auth";
import { ICognitoUserData } from 'amazon-cognito-identity-js'
import React from "react";
import strings from "../literales/loginStrings.json";
//import localUsers from "../usuariosLocales";
import { queryString } from "../libs/Utilities";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types"

interface ILoginFormProps {
    onAuthentication: (authenticatedUser: string) => void;
}

interface ILoginFormState {
    error: string | null;
    usuario: string;
    password: string;
}

export default class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
    constructor(props: ILoginFormProps) {
        super(props);
        this.state = {
            error: null,
            usuario: "",
            password: ""
        };
    }

    render() {
        return (
            <div className="login-form">
                <form id="identityPool" onSubmit={(e) => this.submitHandler(e)}>
                    <div className="form-inner">
                        <h2>{strings.titulo}</h2>
                        <h4>{strings.descripcion}</h4>
                        <h1>
                            <button type="submit" id="identityPoolBtn" className="btn btn-primary">{strings.accederFed}</button>
                        </h1>
                    </div>
                </form>
            </div>
        );
    }

    submitHandler(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        try {
            const prevLocation = decodeURIComponent(queryString("redirect") || "");
            //Auth.signIn(this.state.usuario, this.state.password).then((user: ICognitoUserData) => {
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito, customState: prevLocation }).then(cred => {
                //console.log(`User logged in: ${user.Username}`);
                //console.log(`Login ok!`);
                //console.log(`User logged in: ${cred}`);
                //console.log(user); //Ver detalles de "CognitoUser"
                this.props.onAuthentication(this.state.usuario);
            }, (reason) => {
                console.log(`Sign in not validated`);
                console.log(reason);
                this.setState({
                    error: strings.errorNombreOPassword
                });
            });
            console.log("hola");
        } catch (e) {
            console.log(`Sign in error: ${e}`);
        }
    }
}