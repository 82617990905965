export default class Sesion {
    /**
     * Retrieve key from current sesion
     * @param key 
     * @returns 
     */
    public static getSessionState(key: string): string | null {
        return sessionStorage.getItem(key) || null;
        // alternativamente, usar localStorage en vez de sessionStorage
    };

    /**
     * Add key to current sesion
     * @param key 
     * @param user 
     */
    public static setSessionState(key: string, user: string) {
        sessionStorage.setItem(key, user);
        // alternativamente, usar localStorage en vez de sessionStorage
    };
}