import React, { CSSProperties } from "react";
import strings from "../literales/navigationStrings.json";

export interface INavigationProps {
    onMenuItemChange: (newItem: MenuItem) => void;
}

interface INavigationState {
    activeImportarFicheros: boolean;
    activeCalendario: boolean;
}

export enum MenuItem {
    ImportarFicheros = 1,
    Calendario
}

const naturgyMenuBackground: CSSProperties = {
    backgroundColor: '#004471'
};

const naturgyMenuTitleColor: CSSProperties = {
    color: '#EE7301'
};

export default class Navigation extends React.Component<INavigationProps, INavigationState> {
    constructor(props: INavigationProps) {
        super(props);
        this.state = {
            activeImportarFicheros: true,
            activeCalendario: false
        };
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark " style={naturgyMenuBackground}>
                <div className="navbar-brand" style={naturgyMenuTitleColor}>{strings.titulo}</div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className={`nav-item ${this.getActiveItemClass(MenuItem.ImportarFicheros)}`}>
                            <a className="nav-link"
                                onClick={() => this.setActiveItem(MenuItem.ImportarFicheros)}
                                href="/#">{strings.itemImportarFicheros}</a>
                        </li>

                        <li className={`nav-item ${this.getActiveItemClass(MenuItem.Calendario)}`}>
                            <a className="nav-link"
                                onClick={() => this.setActiveItem(MenuItem.Calendario)}
                                href="/#">{strings.itemCalendario}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }

    private getActiveItemClass(item: MenuItem): string {
        const activeClass: string = "active";
        switch (item) {
            case MenuItem.Calendario:
                return this.state.activeCalendario ? activeClass : "";
            default:    //por defecto ImportarFicheros
                return this.state.activeImportarFicheros ? activeClass : "";
        }
    }

    private setActiveItem(item: MenuItem): void {
        switch (item) {
            case MenuItem.Calendario:
                this.setState({
                    activeImportarFicheros: false,
                    activeCalendario: true
                });
                break;
            default:    //por defecto ImportarFicheros
                this.setState({
                    activeImportarFicheros: true,
                    activeCalendario: false
                });
                break;
        }
        this.props.onMenuItemChange(item);
    }
}