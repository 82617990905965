/**
 * API call returns JSON
 */
export interface IAPIArchivos {
    files: IAPIArchivo[];
}

/**
 * Entry of array "files"
 */
export interface IAPIArchivo {
    id: string,
    usuario: string,
    fichero: string,
    tipo: string,
    fecha_subida: string,
    estado_validacion: string
}

/**
 * Text representation of IAPIArchivo properties
 */
export enum APIArchivoColumnNames {
    ID = "id",
    Usuario = "usuario",
    Fichero = "fichero",
    Tipo = "tipo",
    FechaSubida = "fecha_subida",
    EstadoValidacion = "estado_validacion"
}