import React from "react";
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import strings from '../../literales/uploadStrings.json';
import APIConnect from "../API/APIConnect";
import APIUrls from "../API/APIUrls";
import Fechas from "../Common/Fechas";

export interface IUploaderProps {
  authenticatedUser: string;
  // Notificar al componente padre de se están subiendo ficheros, con la cuenta atrás.
  onUploading: (uploadingWait: number) => void;
}

interface IUploaderState {
}

const dropZoneStyles: React.CSSProperties = {
  minHeight: 160,
  maxHeight: 200
};

const dropZoneActiveStyles: React.CSSProperties = {
  borderColor: "blue"
};

export default class Uploader extends React.Component<IUploaderProps, IUploaderState> {
  constructor(props: IUploaderProps) {
    super(props);
    this.state = {
    };
  }

  private timerRecargaProcesoCompleto: number | undefined = undefined;
  private timerRecargaProcesoCompletoContador: number = 0;

  private handleChangeStatus = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => {
    console.log(status, file);
  }

  private handleSubmit = (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
    console.log(successFiles.map(f => f.meta));

    const url: string = APIUrls.getFileUploadEndpointUrl();
    let fileDate: string = Fechas.formateaFechaHoraFichero(new Date());
    let correct: number = 0, incorrect: number = 0;
    let errorMessages: string = "";

    Promise.all(successFiles.map(async f => {
      let fileName = `${this.props.authenticatedUser}_${fileDate}_${f.meta.name}`;

      await APIConnect.PutFile(url, fileName, f.file).then(resultado => {
        console.log(`Subido fichero "${fileName}": ${resultado}`);
        f.remove(); // remove file from upload dialog
        correct++;
      }, (value) => {
        console.log(`Error fichero "${fileName}": ${value}`);
        incorrect++;
        errorMessages += `${value}\n`;
      });
    })).finally(() => {
      //Cuando ha finalizado todo, mostrar aviso de archivos subidos y aquellos que han dado error.
      if (correct > 0) {
        this.iniciaContadorSubidaFicheros();
      }
      if (incorrect > 0) {
        alert(`${strings.AlertArchivosError}: ${incorrect}\n${errorMessages}`);
      }
    });
  }

  public render() {
    return (
      <div className="table">
        <h3 className="title">{strings.titulo}</h3>
        <Dropzone
          onChangeStatus={this.handleChangeStatus}
          onSubmit={this.handleSubmit}
          inputContent={strings.importar}
          inputWithFilesContent={strings.anadirArchivos}
          submitButtonContent={<div>{strings.submit}</div>}
          styles={{
            dropzone: dropZoneStyles,
            dropzoneActive: dropZoneActiveStyles
          }}
        />
      </div>
    );
  }

  private iniciaContadorSubidaFicheros() {
    if (this.timerRecargaProcesoCompleto !== undefined) {
      return;
    }

    //Inicializa contador a 3 segundos
    this.timerRecargaProcesoCompletoContador = 3;

    //Cada segundo, descuenta, y envía dato al componente padre.
    //Al llegar a 0, desactiva contador.
    this.timerRecargaProcesoCompleto = window.setInterval(() => {
      this.props.onUploading(this.timerRecargaProcesoCompletoContador);

      if (--this.timerRecargaProcesoCompletoContador <= -1) {
        window.clearInterval(this.timerRecargaProcesoCompleto);
        this.timerRecargaProcesoCompleto = undefined;
      }
    }, 1100);
  }
}
