const configAWS = {
  Auth: {
    mandatorySignIn: true,
    region: "eu-west-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "s3",
        region: "eu-west-1",
        endpoint: process.env.REACT_APP_API_S3_ENDPOINT,
        custom_header: async () => {
          return {
            "Content-Type": "application/json"
          }
        }
      },
      {
        name: "dynamodb",
        region: "eu-west-1",
        endpoint: process.env.REACT_APP_API_DYNAMODB_ENDPOINT,
        custom_header: async () => {
          return {
            "Content-Type": "application/json"
          }
        }
      }
    ]
  }
};

export default configAWS;