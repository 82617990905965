import strings from '../../literales/ficherosStrings.json';

export default class ComponentesListas {

    /**
     * Componente visual que informa de los datos de paginación y cantidad de elementos de una lista
     * @param isLoading 
     * @param from 
     * @param to 
     * @param size 
     * @returns 
     */
    public static paginacionTotales(isLoading: boolean, from: number, to: number, size: number): JSX.Element {
        return (
            <span className="react-bootstrap-table-pagination-total">
                &nbsp;{this.getTotalString(isLoading, from, to, size)}
            </span>
        );
    }

    private static getTotalString(isLoading: boolean, from: number, to: number, size: number): string {
        if (isLoading) {
            return strings.Recargando;
        } else {
            return `${strings.TablaResultados} ${from}-${to} ${strings.TablaTotales} ${size}`;
        }
    }

}