import Cadenas from "../Common/Cadenas";

export default class APIUrls {
    /**
     * Return the Endpoint URL for ImportFile API
     * Includes BaseURL, Environment and Method
     */
    public static getFileImportsEndpintUrl(): string {
        let baseUrl: string = process.env.REACT_APP_API_DYNAMODB_ENDPOINT;
        let method: string = process.env.REACT_APP_METODO_CABECERAS;
        let environment: string = process.env.REACT_APP_ENV;

        baseUrl = Cadenas.removeEndOfString(baseUrl.trim(), "/");
        method = Cadenas.removeStartAndEndOfString(method.trim(), "/");
        environment = Cadenas.removeStartAndEndOfString(environment.trim(), "/");

        return `${baseUrl}/${environment}/${method}`;
    }

    /**
     * Return the Endpoint URL for File Error API
     * Includes BaseURL, Environment and Method
     */
    public static getFileErrorEndpointUrl(): string {
        let baseUrl: string = process.env.REACT_APP_API_DYNAMODB_ENDPOINT;
        let method: string = process.env.REACT_APP_METODO_ERRORES_FICHERO;
        let environment: string = process.env.REACT_APP_ENV;

        baseUrl = Cadenas.removeEndOfString(baseUrl.trim(), "/");
        method = Cadenas.removeStartAndEndOfString(method.trim(), "/");
        environment = Cadenas.removeStartAndEndOfString(environment.trim(), "/");

        return `${baseUrl}/${environment}/${method}`;
    }

    /**
     * Return the Endpoint URL for File Upload API
     * Includes BaseURL, Environment and Method
     */
     public static getFileUploadEndpointUrl(): string {
        let baseUrl: string = process.env.REACT_APP_API_S3_ENDPOINT;
        let method: string = process.env.REACT_APP_METODO_UPLOAD_FICHERO;
        let environment: string = process.env.REACT_APP_ENV;

        baseUrl = Cadenas.removeEndOfString(baseUrl.trim(), "/");
        method = Cadenas.removeStartAndEndOfString(method.trim(), "/");
        environment = Cadenas.removeStartAndEndOfString(environment.trim(), "/");

        return `${baseUrl}/${environment}/${method}`;
    }

    /**
     * Return the Endpoint URL for Calendar Tasks API
     * Includes BaseURL, Environment and Method
     */
    public static getCalendarEndpointUrl(): string {
        let baseUrl: string = process.env.REACT_APP_API_DYNAMODB_ENDPOINT;
        let method: string = process.env.REACT_APP_METODO_CALENDARIO;
        let environment: string = process.env.REACT_APP_ENV;

        baseUrl = Cadenas.removeEndOfString(baseUrl.trim(), "/");
        method = Cadenas.removeStartAndEndOfString(method.trim(), "/");
        environment = Cadenas.removeStartAndEndOfString(environment.trim(), "/");

        return `${baseUrl}/${environment}/${method}`;
    }

}