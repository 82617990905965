/**
 * API call returns JSON
 */
export interface IAPITareasCalendarios {
    files: IAPITareasCalendario[];
}

/**
 * Entry of array "files"
 */
export interface IAPITareasCalendario {
    id: string,
    nombretemplate: string,
    tipo: string,
    ultimo_status: string,
    fecha_del_ultimo_fichero: string,
    fechaesperada_ini: string,
    fechaesperada_fin: string
}

/**
 * Text representation of IAPITareasCalendario properties
 */
export enum APITareasCalendarioColumnNames {
    ID = "id",
    NombreTemplate = "nombretemplate",
    Tipo = "tipo",
    UltimoStatus = "ultimo_status",
    FechaUltimo = "fecha_del_ultimo_fichero",
    FechaEsperadaInicio = "fechaesperada_ini",
    FechaEsperadaFin = "fechaesperada_fin"
}