import React from "react";
import ListaArchivos from "./ListaArchivos";
import ListaErrores from "./ListaErrores";
import Uploader from "./Uploader";

export interface IImportarFicherosProps {
  authenticatedUser: string;
}

interface IImportarFicherosState {
  selectedUser: string | null;
  selectedDate: string | null;
  selectedFile: string | null;
  contadorSubida: number;
}

export default class ImportarFicheros extends React.Component<IImportarFicherosProps, IImportarFicherosState> {
  constructor(props: IImportarFicherosProps) {
    super(props);
    this.state = {
      selectedUser: null,
      selectedDate: null,
      selectedFile: null,
      contadorSubida: 0
    };
  }

  render() {
    return (
      <div>
        <Uploader
          authenticatedUser={this.props.authenticatedUser}
          onUploading={(contador) => { this.setState({ contadorSubida: contador }) }} />
        <ListaArchivos onUserSelected={
          (user: string, date: string, file: string) => this.setState({
            selectedUser: user,
            selectedDate: date,
            selectedFile: file
          })}
          contadorSubida={this.state.contadorSubida} />
        {
          this.state.selectedUser && this.state.selectedDate && this.state.selectedFile &&
          <ListaErrores
            usuario={this.state.selectedUser}
            fecha={this.state.selectedDate}
            fichero={this.state.selectedFile} />
        }
      </div>
    );
  }
}
