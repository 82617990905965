
export default class Cadenas {
    /**
     * Escapar cadena para que sea compatible con expresiones regulares
     * @param s 
     * @returns 
     */
    public static escapeRegExp(s: string) {
        if (!s) {
            return "";
        }
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    /**
     * Eliminar todo rastro de la cadena especificada del final de una cadena
     * @param s Cadena a limpiar
     * @param remove Cadena a buscar
     * @returns Cadena sin los caracteres especificados al final
     */
    public static removeEndOfString(s: string, remove: string): string {
        while (s.endsWith(remove)) {
            s = s.substring(0, (s.length) - (remove.length));
        }
        return s;
    }

    /**
     * Eliminar todo rastro de la cadena especificada al principio de una cadena
     * @param s Cadena a limpiar
     * @param remove Cadena a buscar
     * @returns Cadena sin los caracteres especificados al principio
     */
    public static removeStartOfString(s: string, remove: string): string {
        while (s.startsWith(remove)) {
            s = s.substring(remove.length);
        }
        return s;
    }

    /**
     * Eliminar todo rastro de la cadena especificada al principio y al final de una cadena
     * @param s Cadena a limpiar
     * @param remove Cadena a buscar
     * @returns Cadena sin los caracteres especificados al principio y al final
     */
    public static removeStartAndEndOfString(s: string, remove: string): string {
        return this.removeEndOfString(this.removeStartOfString(s, remove), remove);
    }
}