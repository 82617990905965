const configAuth = {
  "domain": process.env.REACT_APP_COGNITO_DOMAIN,
  "scope": [
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  "redirectSignIn": process.env.REACT_APP_REDIRECT_URL,
  "redirectSignOut": process.env.REACT_APP_REDIRECT_URL,
  "responseType": "token"
};

export default configAuth;