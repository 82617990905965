import strings from '../../literales/fechasStrings.json';

export default class Fechas {

    /**
     * Formatea fecha en modo SQL: YYYY-MM-dd HH:mm:dd
     * @param fecha 
     * @returns 
     */
    public static formateaFechaHoraTipoSQL(fecha: Date) {
        let YYYY = fecha.getFullYear();
        let MM = this.padWithZero(fecha.getMonth() + 1);    // getMonth devuelve 0-11
        let dd = this.padWithZero(fecha.getDate());
        let HH = this.padWithZero(fecha.getHours());
        let mm = this.padWithZero(fecha.getMinutes());
        let ss = this.padWithZero(fecha.getSeconds());

        return `${YYYY}-${MM}-${dd} ${HH}:${mm}:${ss}`;
    }

    /**
     * Formatea fecha para usarlo en la subida de archivos
     * @param fecha Fecha como objeto Date()
     * @returns Fecha en texto formato YYYYMMddHHmmss
     */
    public static formateaFechaHoraFichero(fecha: Date): string {
        let YYYY = fecha.getFullYear();
        let MM = this.padWithZero(fecha.getMonth() + 1);    // getMonth devuelve 0-11
        let dd = this.padWithZero(fecha.getDate());
        let HH = this.padWithZero(fecha.getHours());
        let mm = this.padWithZero(fecha.getMinutes());
        let ss = this.padWithZero(fecha.getSeconds());

        return `${YYYY}${MM}${dd}${HH}${mm}${ss}`;
    }

    /**
     * Formatea fecha para usarlo en la consulta de subida de archivos
     * @param fechaText Fecha en texto en formato 'YYYY-MM-dd HH:mm:dd'
     * @returns Fecha en texto formato YYYYMMddHHmmss
     */
    public static formateaTextoFechaHoraFichero(fechaText: string): string {
        //simplemente elimina espacios y caracteres separadores
        return fechaText.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', '');
    }

    /**
     * Formatea fecha para usarlo en la consulta de Tareas de Calendario
     * @param fecha 
     * @returns 
     */
    public static formateaFechaHoraCalendario(anyo:number, mes:number):string {
        let MM = this.padWithZero(mes);    // getMonth devuelve 0-11
        return `${anyo}${MM}`;
    }

    /**
     * Obtener los últimos 5 años comenzando por el actual, en orden descendente
     * @returns 
     */
    public static obtenerHistoricoFechas(): number[] {
        let anyosHistorico: number[] = [];
        let anyo: number = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
            anyosHistorico.push(anyo);
            anyo--;
        }
        return anyosHistorico;
    }

    /**
     * Obtener listado de meses de forma ascendente
     * @returns 
     */
    public static obtenerMesesAsc(): string[] {
        return strings.meses;
    }

    /**
     * Obtener listado de meses de forma descendente
     * @returns 
     */
    public static obtenerMesesDesc(): string[] {
        return this.obtenerMesesAsc().reverse();
    }

    public static isCurrentYear(anyo: number): boolean {
        let currentAnyo: number = new Date().getFullYear();
        return anyo === currentAnyo;
    }

    private static padWithZero(value: number): string {
        return ((value >= 0 && value < 10) ? '0' : '') + value;
    }
}