export default class APIConnect {
    /**
     * Call Post method
     * @param url Base URL+Environment+Method
     * @param delay Minimum delay, to avoid "glitch" appearence of spinner on fast loads
     * @returns 
     */
    public static Post<T>(url: string, delay?: number): Promise<T> {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders
        };
        
        return new Promise<T>((resolve, reject) => {
            fetch(url, requestOptions)
                .then((value: Response) => {
                    value.json()
                        .then((jsonResult: T) => {
                            if (delay) {
                                window.setTimeout(() => resolve(jsonResult), delay);
                            } else {
                                resolve(jsonResult);
                            }
                        })
                        .catch(e => {
                            let error = `Post error json parse: ${e}`;
                            if (delay) {
                                window.setTimeout(() => reject(error), delay);
                            } else {
                                reject(error);
                            }
                        })
                })
                .catch(e => {
                    let error = `Post error fetch: ${e}`;
                    if (delay) {
                        window.setTimeout(() => reject(error), delay);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    /**
     * Call PostData method
     * @param url Base URL+Environment+Method
     * @param delay Minimum delay, to avoid "glitch" appearence of spinner on fast loads
     * @param data Part of the name of the file to get
     * @returns 
     */
     public static PostData<T>(url: string, data: string, delay?: number): Promise<T> {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
        myHeaders.append("Content-Type", "application/json");
        
        const requestOptions: RequestInit = {
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders,
            body: JSON.stringify({ file_id: data })
        };
        
        return new Promise<T>((resolve, reject) => {
            fetch(url, requestOptions)
                .then((value: Response) => {
                    value.json()
                        .then((jsonResult: T) => {
                            if (delay) {
                                window.setTimeout(() => resolve(jsonResult), delay);
                            } else {
                                resolve(jsonResult);
                            }
                        })
                        .catch(e => {
                            let error = `PostData error json parse: ${e}`;
                            if (delay) {
                                window.setTimeout(() => reject(error), delay);
                            } else {
                                reject(error);
                            }
                        })
                })
                .catch(e => {
                    let error = `PostData error fetch: ${e}`;
                    if (delay) {
                        window.setTimeout(() => reject(error), delay);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    /**
     * Call Put method
     * @param url BaseURL+Environment+Method
     * @param filename Filename to use (different from actual file.name)
     * @param file File object to upload
     * @returns 
     */
    public static PutFile(url: string, filename: string, file: File): Promise<string> {
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);

        let formdata: FormData = new FormData();
        let filepath: string = `/upload_files/${filename}`;
        formdata.append(filename, file);

        const requestOptions: RequestInit = {
            method: 'PUT',
            body: formdata,
            redirect: 'follow',
            headers: myHeaders
        };

        return new Promise<string>((resolve, reject) => {
            fetch(url + filepath, requestOptions)
                .then((value: Response) => {
                    value.text()
                        .then((textResult: string) => {
                            resolve(textResult);
                        })
                        .catch(e => {
                            reject(`PutFile error json parse: ${e}`);
                        })
                })
                .catch(e => {
                    reject(`PutFile error fetch: ${e}`);
                });
        });
    }
}
