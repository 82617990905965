import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './performance/reportWebVitals';
import Amplify, { Auth } from 'aws-amplify';
import configAWS from './config_aws';
import configAuth from './config_auth';

import './styles/index.css'
import './styles/app.css';
import './styles/controls.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-dropzone-uploader/dist/styles.css';

Amplify.configure(configAWS);
Auth.configure({ oauth: configAuth });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
