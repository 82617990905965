import React from "react";
import { APITareasCalendarioColumnNames, IAPITareasCalendario, IAPITareasCalendarios } from "../Models/APITareasCalendario";
import strings from '../../literales/calendarioStrings.json';
import dateStrings from '../../literales/fechasStrings.json';
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import APIConnect from "../API/APIConnect";
import APIUrls from "../API/APIUrls";
import Fechas from "../Common/Fechas";

//import taskData from '../../exampleData/taskData.json';

export interface ICalendarioProps {
  authenticatedUser: string;
}

interface ICalendarioState {
  tareas: IAPITareasCalendario[];
  tareasFiltradas: IAPITareasCalendario[];
  loadingData: boolean;
  selectedYear: number;
  selectedMonth: number;
}

export default class Calendario extends React.Component<ICalendarioProps, ICalendarioState> {
  constructor(props: ICalendarioProps) {
    super(props);
    this.state = {
      tareas: [],
      tareasFiltradas: [],
      loadingData: false,
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth() + 1
    };
  }

  private timerRecargaLista: number | undefined = undefined;

  public componentDidMount() {
    this.loadData();
    this.timerRecargaLista = window.setInterval(() => {
      this.loadData();
    }, 30000); //30segundos
  }

  public componentWillUnmount() {
    window.clearInterval(this.timerRecargaLista);
  }

  private filterColumns: ColumnDescription<any, any>[] = [
    /*{
      dataField: APITareasCalendarioColumnNames.ID,
      text: "ID"
    },*/
    {
      dataField: APITareasCalendarioColumnNames.NombreTemplate,
      text: strings.columnaTemplate
    },
    {
      dataField: APITareasCalendarioColumnNames.Tipo,
      text: strings.columnaTipo
    },
    {
      dataField: APITareasCalendarioColumnNames.UltimoStatus,
      text: strings.columnaUltimoEstatus
    },
    {
      dataField: APITareasCalendarioColumnNames.FechaUltimo,
      text: strings.columnaFechaUltimoCambio
    },
    {
      dataField: APITareasCalendarioColumnNames.FechaEsperadaInicio,
      text: strings.columnaFechaEsperadaInicio
    },
    {
      dataField: APITareasCalendarioColumnNames.FechaEsperadaFin,
      text: strings.columnaFechaEsperadaFin
    }
    
  ];

  private defaultSorted: [{ dataField: any; order: SortOrder; }] | undefined = [{
    dataField: APITareasCalendarioColumnNames.ID,
    order: "desc"
  }];

  render() {
    return (
      <>
        <div className="table">
          <h3 className="title">{strings.titulo}</h3>
          <div>

            <div className="container">
              <div className="row">
                <div className="col-xs-1">

                  {/* Desplegable año */}
                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style={{ width: 100 }}>
                      {strings.botonAnyo}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {
                        Fechas.obtenerHistoricoFechas().map((a,idx) => {
                          let active = this.state.selectedYear === a ? " active" : "";
                          return (
                            <a key={idx} className={`dropdown-item${active}`} href="/#"
                              onClick={() => this.setState({ selectedYear: a }, this.updateFilterTasks)}
                            >{a}</a>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>
                <div className="col-xs-1">

                  {/* Desplegable mes */}
                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style={{ width: 100 }}>
                      {strings.botonMes}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {
                        Fechas.obtenerMesesDesc().map((NombreMes, idx) => {
                          let idMes: number = 12 - idx;
                          let active = this.state.selectedMonth === idMes ? " active" : "";
                          return (
                            <a key={idx} className={`dropdown-item${active}`} href="/#"
                              onClick={() => this.setState({ selectedMonth: idMes }, this.updateFilterTasks)}
                            >{NombreMes}</a>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>
                <div className="col-xs-6">&nbsp;</div>
                <div className="col-xs-2">
                  Mostrando datos de {dateStrings.meses[12 - this.state.selectedMonth]} {this.state.selectedYear}
                </div>
              </div>
            </div>
          </div>
          <BootstrapTable
            keyField={APITareasCalendarioColumnNames.ID}
            columns={this.filterColumns}
            data={this.state.tareasFiltradas}
            defaultSorted={this.defaultSorted}
            hover={true}
          />
        </div>
        <div className="tableSeparator"></div>

        { /* uncomment to refresh manually*/
          <a href="/#" onClick={() => this.loadData()}>Recarga</a>
        }
      </>
    );
  }

  private loadData() {
    /* Testing with example Data */
    //this.setState({ tareas: taskData.files, loadingData: false });
    //return;

    this.setState({
      loadingData: true
    }, this.getAPIData);
  }

  private getAPIData(): void {
    const url: string = APIUrls.getCalendarEndpointUrl();

    APIConnect.Post<IAPITareasCalendarios>(url, 500).then((responseValue) => {
      console.log(responseValue.files);
      responseValue.files.forEach( function(valor, indice, array) {
        valor.nombretemplate = valor.nombretemplate.replaceAll("_","-");
      });
      this.setState({
        tareas: responseValue.files,
        loadingData: false
      }, this.updateFilterTasks);
    }, (rejectError) => {
      console.log(`Error API calendario: ${rejectError}`);
      this.setState({ loadingData: false });
    });
  }

  private updateFilterTasks() {
    let filtro: string = Fechas.formateaFechaHoraCalendario(this.state.selectedYear, this.state.selectedMonth);
    let tareas: IAPITareasCalendario[] = this.state.tareas.filter(x => x.id.indexOf(filtro) !== -1);
    this.setState({
      tareasFiltradas: tareas,
    });
  }
}
